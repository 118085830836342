<p-toast position="top-center" key="confirm" (onClose)="cancelarMensajeConfirmacion()" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{message.summary}}</h4>
        <p>{{message.detail}}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button type="button" pButton (click)="cancelarMensajeConfirmacion()" label="Cancelar"
            class="p-button-secondary"></button>
        </div>
        <div class="col-6">
          <button type="button" pButton (click)="confirmarMensaje()" label="Aceptar" class="p-button-success"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 98%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-calendar-plus mt-3 mb-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <div class="row">
              <div class="col-md-1">
                <div class="cardicono rounded">
                  <i class="pi pi-calendar-plus mt-3 mb-3" style="font-size: 2rem; color: #fff;"></i>
                </div>
              </div>
              <div class="col-md-7 ">

                <header style="left: 3%; top: 5%;">
                  <div style="display:inline">Gestión de historial </div>
                  <div style="color:rgb(19, 225, 197);display:inline;"> Caja de Ahorro anual </div>
                </header>
              </div>
              <div class="col-md-4">
                <div class="justify-content-end">

                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-around mt-4">
              <div class="row d-flex justify-content-around mt-4">
                <div class="col-md-6">
                  <div class="row d-flex justify-content-around">
                    <div class="col-md-5">
                      <p-calendar [(ngModel)]="anio" view="year" [showIcon]="true" dateFormat="yy" inputId="yearpicker"
                        [style]="{'width':'100%','height':'80%'}" pTooltip="Seleccione Año"></p-calendar>
                    </div>
                    <div class="col-md-3">
                      <button pButton pRipple label="Filtrar" class="p-button-primary" icon="pi pi-filter"
                        (click)="cargarInformacion(anio);" pTooltip="Cargar Información"></button>
                    </div>
                  </div>
                </div>

                <div class="col-md-2"></div>
                <div class="col-md-2 d-flex align-items-center">
                  <div class="justify-content-end">
                    <p-button label="Pago anual" icon="pi pi-list" iconPos="right"
                      styleClass="p-button-raised p-button-danger" (click)="generarpagoanual();"
                      pTooltip="pago anual de caja de ahorro" tooltipPosition="left">
                    </p-button>
                  </div>


                </div>
                <div class="col-md-2 ">
                  <button type="button" pButton pRipple class="p-button-success " pTooltip="Exportar Catalogo a EXCEL"
                      tooltipPosition="right" (click)="abrirModalReportes()" [disabled]="ButtonExportExcel">
                      <i class="pi pi-file-excel"></i> Exportar
                    </button>

                </div>

              </div>
            </div>
            <div class="card-content">
              <div class="row mt-3">
                <p-table #dt1 [value]="lstColaboradores" [tableStyle]="{'min-width': '50rem'}"
                  styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                  responsiveLayout="scroll" [globalFilterFields]="['nombre_completo','desc_puesto','desc_ciudad_hm']"
                  [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true"
                  [resizableColumns]="false" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                  [loading]="loading">

                  <ng-template pTemplate="caption">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="col-md-4 d-flex justify-content-end">
                          <!-- <div>
                            <p-button label="Registrar Ahorro" icon="pi pi-plus" iconPos="right"
                              (click)="registrarAhorro(1);">
                            </p-button>
                          </div> -->
                        </div>
                      </div>
                      <div class="col-md-3">
                        <p-columnFilter field="id_ciudad_principal" matchMode="contains" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" [options]="lstCiudadPrincipal" optionLabel="nombre"
                              optionValue="id" (onChange)="filter($event.value)" placeholder="Filtrar por ciudad"
                              [showClear]="true">
                            </p-dropdown>
                          </ng-template>
                        </p-columnFilter>
                      </div>
                      <div class="col-md-3">
                        <p-columnFilter field="clv_activo" matchMode="contains" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" [options]="lstEstatus" optionLabel="nombre" optionValue="id"
                              (onChange)="filter($event.value)" placeholder="Filtrar por Estatus" [showClear]="true">
                            </p-dropdown>
                          </ng-template>
                        </p-columnFilter>
                      </div>
                      <div class="col-md-4">
                        <div class="flex justify-content-end">
                          <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                              placeholder="Buscar Nombre,Puesto" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="idx" class="text-center"># Chofer<p-sortIcon field="idx"></p-sortIcon> </th>
                      <th pSortableColumn="nombres">Nombrecompleto<p-sortIcon field="nombres"></p-sortIcon></th>
                      <th class="text-center">Fec.Alta</th>
                      <th class="text-center">Acumulado</th>
                      <th class="text-center">porcentaje</th>
                      <th class="text-center">imp.interes</th>
                      <th class="text-center">Acumulado con prospeccion</th>
                      <th pSortableColumn="desc_puesto">Puesto<p-sortIcon field="desc_puesto"></p-sortIcon></th>
                      <th pSortableColumn="nombre_ciudad_hm">Ciudad<p-sortIcon field="nombre_ciudad_hm"></p-sortIcon>
                      </th>
                      <!-- <th class="text-center">Fecha de Registro</th> -->
                      <th class="text-center">Importe Semanal</th>
                      <th class="text-center">Estatus</th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data let-index>
                    <tr>
                      <td class="text-center texto_chofer_lila">
                        {{data.cod_chofer}}
                      </td>
                      <td class="texto_resaltado">
                        {{data.nombre_completo }}
                      </td>
                      <td class="texto_resaltado">
                        {{data.fec_alta | date: 'dd/MM/yyyy'}}
                      </td>
                      <td class="text-center" style="min-width:200px ;max-width: 200px; color: rgb(32, 202, 10);"
                        [ngClass]="{'ajustarcelda':true}">
                        <p class="my-0"><b>{{data.imp_ahorrado | currency: 'USD'}}</b>

                          <button class=" ml-2 btn btn-light" pTooltip="Mostrar historico " tooltipPosition="top"
                            (click)="mostrardesgloce(data);">
                            <i class="pi pi-book"></i>
                          </button>

                        </p>
                      </td>
                      <td class="text-center " style="color:rgb(30, 27, 219);">
                        <b> {{ data.porcentaje * 100}}%</b>
                      </td>
                      <td class="text-center " style="color: rgb(38, 113, 234);">
                        <b>{{ data.imp_interes | currency: 'USD'}}</b>
                      </td>
                      <td class="text-center " style="color: rgb(0, 0, 0);">
                        <b> {{ data.imp_prospeccion | currency: 'USD'}}</b>
                      </td>

                      <td
                        [ngClass]="{'texto_chofer': data.id_puesto==1,'texto_auxiliar':data.id_puesto==2,'texto_bk':data.id_puesto==3}">
                        {{ data.desc_puesto}}
                      </td>
                      <td class=""> {{data.nombre_ciudad_hm}}</td>
                      <!-- <td class="text-center">
                        {{ data.fecha_registro | date: 'dd/MM/yyyy'}}
                      </td> -->
                      <td class="text-center " style="color: rgb(233, 50, 30);">
                        <b>{{ data.imp_ahorro | currency}}</b>
                      </td>
                      <td class="text-center">
                        <p-tag *ngIf="data.clv_activo==1" severity="success" value="Activo"></p-tag>
                        <p-tag *ngIf="data.clv_activo==0" [style]="{ 'background': '#adacac'}" value="Inactivo"></p-tag>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <tr>
                      <td colspan="2" class="text-right"></td>
                      <td colspan="2" class="text-right" style="color: rgb(169, 12, 12);">Caja de ahorro</td>
                      <td style="color: rgb(13, 164, 13);">{{ imp_total_general | currency: 'USD'}}</td>
                      <td colspan="2" class="text-right" style="color: rgb(169, 12, 12);">Interes</td>
                      <td style="color: rgb(13, 164, 13);">{{ imp_interes | currency: 'USD'}}</td>
                      <td colspan="2" class="text-right" style="color: rgb(169, 12, 12);">Caja de ahorro prospeccion
                      </td>
                      <td style="color: rgb(13, 164, 13);">{{ imp_total_general_propeccion | currency: 'USD'}}</td>
                    </tr>
                  </ng-template>
                </p-table>

                <div class=" mt-3 d-flex justify-content-center">
                  <p class="text-left informativo"
                    style="display: inline; padding-left: 10px; border-left: 5px solid rgb(67, 192, 35); font-weight: normal; font-size: 0.8rem;">
                    <b>ACTIVO</b> El colaborador se considera en la caja de ahorro
                  </p>
                  <p class="text-left informativo"
                    style="display: inline; padding-left: 10px; border-left: 5px solid rgb(201, 199, 199); font-weight: normal; font-size: 0.8rem;">
                    <b>INACTIVO</b> el colaborador no es considerado para la caja de ahorro
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <p-dialog [resizable]="false" [draggable]="false" (onClose)="cancelarVentanaModal()" [(visible)]="dsphistorico"
        [style]="{width: '75vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow">
        <ng-template pTemplate="header">
          Historico de caja de ahorro por colaborador
          <div style="font-size: 18px;">
            <!--<p style="color: orange;display: inline;">{{nombre_data_Editable }}</p>&nbsp;-->
            <!-- <p style="color:black; display: inline; ">Fecha: {{dia | date: 'dd/MM/yyyy'}}</p> -->

          </div>
        </ng-template>

        <ng-template pTemplate="content">
          <div class="mt-5">
            <p-table #dt2 [value]="lstahorro" [tableStyle]="{'min-width': '50rem'}"
              styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="550px"
              responsiveLayout="scroll" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,25,50,100]"
              [showCurrentPageReport]="true" [resizableColumns]="false"
              currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading">
              <ng-template pTemplate="caption">
                <form class="form_cliente">
                  <div class="row  mt-1 mb-1">
                    <div class=" col-md-5 d-flex justify-content-center  ">Colaborador : &nbsp;<label
                        style="color: #23c00e;"> {{nombre_completo}}</label> </div>
                    <!-- </div>
         <div class="row mb-1 "> -->
                    <div class=" col-md-3 d-flex justify-content-center  ">Codigo de chofer :&nbsp;<label
                        style="color: #23c00e;"> {{cod_chofer}}</label> </div>

                    <div class=" col-md-4 d-flex justify-content-center ">Importe acumulado : &nbsp;<label
                        style="color: #23c00e;"> {{imp_total}}</label> </div>
                  </div>
                </form>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <!-- <th class="text-center" pSortableColumn="cod_chofer">cod_chofer<p-sortIcon
                    field="cod_chofer"></p-sortIcon></th> -->
                  <th class="text-center" pSortableColumn="id_semana">Id semana<p-sortIcon
                      field="id_semana"></p-sortIcon>
                  </th>
                  <th class="text-center" pSortableColumn="fec_inicio">Inicio<p-sortIcon
                      field="fec_inicio"></p-sortIcon>
                  </th>
                  <th class="text-center" pSortableColumn="fec_fin">Fin<p-sortIcon field="fec_fin"></p-sortIcon></th>
                  <th class="text-center">Año</th>
                  <th class="text-center" pSortableColumn="imp_ahorro">Importe de ahorro<p-sortIcon
                      field="imp_ahorro"></p-sortIcon></th>
                  <th class="text-center" pSortableColumn="clv_pagado">Estatus<p-sortIcon
                      field="clv_pagado"></p-sortIcon></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-data>
                <tr [ngClass]="{'row-accessories': data.clv_activo ===0}">

                  <!-- <td class="text-center"><b>{{data.cod_chofer}}</b></td> -->
                  <td class="text-center" style="color: rgb(255,193,7)"><b>{{data.id_semana}}</b></td>
                  <td class="text-center" style="color: rgb(13,110,253);"><b>{{data.fec_inicio | date: 'dd/MM/yyyy'
                      }}</b>
                  </td>
                  <td class="text-center" style="color: orange;"><b>{{data.fec_fin | date: 'dd/MM/yyyy' }}</b></td>
                  <td class="text-center"> <b>{{data.num_anio}}</b></td>
                  <td class="text-center" [ngStyle]="{'color': data.clv_estatus == 1 ? 'rgb(33, 192, 22)' : 'rgb(192, 33, 33)'}">
                    <b>{{ data.clv_estatus == 1 ?  (data.imp_ahorro | currency): '-' + (data.imp_ahorro | currency)}}</b>
                  </td> <!-- <td class="text-center" style="color: rgb(255,193,7)" ><b>{{data.clv_pagado}}</b></td> -->
                  <td class="text-center">
                    <p-tag *ngIf="data.clv_estatus==1" severity="success" value="Ingreso"></p-tag>
                    <p-tag *ngIf="data.clv_estatus==2" [style]="{ 'background': '#e61216'}" value="Retiro"></p-tag>
                    <p-tag *ngIf="data.clv_estatus==3" [style]="{ 'background': '#FFA500'}" value="Pagado al finalizar el año"></p-tag>
                    <p-tag *ngIf="data.clv_estatus==4" [style]="{ 'background': '#e61216'}" value="Finiquito"></p-tag>
                  </td>

                </tr>
              </ng-template>
            </p-table>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarVentanaModal()"></button>
        </ng-template>
      </p-dialog>

      <!-- generar caja de ahorro anual -->



      <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dsppagoanual"
      [style]="{width: '60vw','height':'550px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalanual()">
      <ng-template pTemplate="header">
        {{ texto_operacion }}
        <div *ngIf="nombre_registro" style="color:black;">
          {{nombre_registro }}
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <form #form="ngForm" (ngSubmit)="pagoAnual()">
            <span class="title-light mt-3" style="color: rgb(158, 217, 232);">Información de Caja de Ahorro<i class="pi pi-cog" style="size: 2rem;"></i>
            </span>

            <div class="row mt-4">

              <!-- Caja de Ahorro Anual -->
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <label class="col-form-label color-font"
                      style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                      <span class="input-group-addon ml-2">
                        <i class="pi pi-wallet color-icon mr-4" style="font-size: 2rem; color: #3B82F6;"></i>
                      </span>
                      Caja de Ahorro Anual
                    </label>
                  </div>
                </div>
              </div>

              <!-- Año -->
              <div class="col-md-4">
                <div class="row">
                  <div class="row d-flex justify-content-start">
                    <div class="col-md-3">
                      <label class="col-form-label color-font text-right"
                        style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                        <span class="input-group-addon ml-2">
                          <i class="pi pi-calendar color-icon mr-4" style="font-size: 2rem; color: #3B82F6;"></i>
                        </span>
                        Año
                      </label>
                    </div>
                    <div class="col-sm-9 mt-3">
                      <select class="form-control" style="width: 100%;" [(ngModel)]="num_anio" name="num_anio">
                        <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Ciudad -->
              <div class="col-md-4">
                <div class="row">
                  <div class="row d-flex justify-content-start">
                    <div class="col-md-3 text-center">
                      <label class="col-form-label color-font">
                        <span class="input-group-addon">
                          <i class="pi pi-globe color-icon mr-3" style="font-size: 2rem"></i>
                        </span>
                        Ciudad
                      </label>
                    </div>
                    <div class="col-sm-9">
                      <p-dropdown [options]="lstCiudadPrincipal" autoWidth="false" [style]="{'width':'100%'}"
                        placeholder="Ciudad de corte" optionLabel="nombre" [showClear]="true" filterBy="nombre"
                        [(ngModel)]="id_ciudad_principal" name="id_ciudad_principal">
                      </p-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-7"></div>
            <div class="row d-flex justify-content-center mt-5">
              <div class="col-2">
                <button type="submit" class="btn btn-success w-100">
                  <span class="text-white">Guardar</span>
                  <i class="pi pi-save text-white"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalanual();"></button>
      </ng-template>
    </p-dialog>

       <!-- p-dialog de reportes excel ------------ -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
    [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
    (onHide)="cancelarModalReportes()">
    <ng-template pTemplate="header">
      Generar Reporte De Excel
    </ng-template>
    <ng-template pTemplate="content">
      <div class="mt-5">
        <hr class="style-two">
        <div class="row">
          <div class="col-md-3">
            <p class="texto-pesado">Reporte De Bono Aguinaldo</p>
          </div>
          <div class="col-md-2">
            <select class="form-control" style="width: 100%;" [(ngModel)]="num_anio_rpt">
              <option *ngFor="let year of years" [value]="year">{{ year }}</option>
            </select>
          </div>
          <div class="col-md-3">
            <p-dropdown [(ngModel)]="id_ciudad_principal_rpt" [options]="lstCiudadPrincipal2" optionLabel="nombre"
              optionValue="id" placeholder="Filtrar por Ciudad">
            </p-dropdown>
          </div>
          <div class="col-md-3 ml-6">
            <button type="button" class="btn btn-outline-success" style="width: 100%;"
              (click)="exportarExcel(num_anio_rpt, id_ciudad_principal.id)" [disabled]="ButtonExportExcel">
              Exportar <i class="pi pi-file-export"></i>
            </button>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-2 text-center">
            <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarModalReportes();"></button>
    </ng-template>
  </p-dialog>
    </div>
  </div>
