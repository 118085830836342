import { Component, ViewChild } from '@angular/core';
import { ICiudad } from '../models/ciudad';
import { Table } from 'primeng/table';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { IDiaFestivo, IDiaFestivoColabDisp, IMovDiasFestivos, IPostColabDiaFestivo, IUltRutasDiaFestivo } from '../models/diaFestivoColab';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { ConfirmationService, MessageService, ConfirmEventType } from 'primeng/api';
import ICredencialUsuario from '../models/credencial';
import { IMes } from '../models/mes';
import { AuthService } from '../services/auth.service';
import { CiudadService } from '../services/ciudad.service';
import { ClienteService } from '../services/cliente.service';
import { DiafestivoColabServiceService } from '../services/diafestivo-colab-service.service';
import { SemanaService } from '../services/semana.service';
import { ISemanasHappyMile } from '../models/semanas';

@Component({
  selector: 'app-diafestivo',
  templateUrl: './diafestivo.component.html',
  styleUrls: ['./diafestivo.component.scss']
})
export class DiafestivoComponent {
  @ViewChild('dt1') dt!: Table;
  @ViewChild('dt2') dt2!: Table;
  lstCiudad: ICiudad[] = [];
  lstChoferes: IDiaFestivoColabDisp [] = [];
  lstDiasFestivos: IDiaFestivo[] = [];
  lstMovDiaFestivoColab: IMovDiasFestivos[] = [];
  lstRutasDisp: IUltRutasDiaFestivo[] = [];
  lstCiudades: ICiudad[] = [];
  mssgListaCiudades: string = '';
  dgAddMovimiento:boolean = false;
  boxChofer:boolean = false;
  boxRutas:boolean = false;
  dgRutas:boolean = false;
  desc_ruta:string = '';
  editarRuta:boolean = false;
  desc_ciudad:string = '';

  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };

  lstSemanas: ISemanasHappyMile[] = [];
  anio: any;
  anio_filtro: any;
  loading: boolean = false;
  //variables usadas para la fecha, fueron casteadas ya que presentaban errorees
  fecha_hoy: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_filtrada: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  id_ciudad_hm: number = 0;
  dspCliente: boolean = false;
  texto_operacion: string = '';
  num_operacion: number = 0;
  clv_operacion: number = 0;
  habilitar_agregar: boolean = false;

  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };

  // variables de reportes--------------------------
  dbsReportes: boolean = false;
  fecha_fin: any = null;
  ButtonExportExcel: boolean = false;
  //Fecha de Dias Atras
  fecha_dos_dias_anterior!: Date;
  fecha_del_dia: any = null;
  btnHabilitar: boolean = false;
  mostrar_texto_fechas: number = 0;
  fecha_final_dia!: Date;
  //Fecha para restar 3 dias
  fecha_minus3 !: Date;
  habilitar_tres_dias: boolean = true;
  fecha_dia_real: any = null;
  checkboxValue: boolean = false;
  checked:  boolean = false;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }

  anioReporte1: any;
  loading_reporte1: boolean = false;
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];

  reactiveForm: FormGroup = new FormGroup({
    idx_pao_diario_unidades: new FormControl(0, [Validators.required]),
    id_dia_festivo: new FormControl(null, [Validators.required]),
    chofer: new FormControl({value:null,disabled:true}, [Validators.required ]),
    dataServicio: new FormControl({}),
    desc_ruta: new FormControl(''),
    fecha_dia_festivo: new FormControl({value:'',disabled:true})
  });

  //Permisos
  user: ICredencialUsuario = {};
  //Validaciones de Tiempo
  dsblstCiudades: boolean=true;
  dsblstColaboradores: boolean=true;
  dsblstCiudadesAdscritas: boolean=true;
  constructor(private router: Router,
    private diafestivocolabservice: DiafestivoColabServiceService,
    private semanaService: SemanaService,
    private ciudadService: CiudadService,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    private clienteService: ClienteService, private auth: AuthService) {
    this.cargarInformacionUsuario();
    this.inicializarFechas();

    this.reactiveForm.controls['id_dia_festivo'].valueChanges.subscribe(data => {
      if(typeof data !=='undefined' && data != null)
      {
        this.desc_ciudad = '';
        this.desc_ruta = '';
        this.lstChoferes = [];
        this.lstRutasDisp = [];
        let fest = this.lstDiasFestivos.find(f => f.idx == data)?.fecha.substring(0,10);
        this.reactiveForm.get('fecha_dia_festivo')?.setValue(fest);
        this.reactiveForm.get('fecha_dia_festivo')?.disable();
        this.cargaListaChoferes(data);
        this.reactiveForm.get('chofer')?.enable();
      }
    });

    this.reactiveForm.controls['chofer'].valueChanges.subscribe(data => {
      if(typeof data !== 'undefined' && data != null)
        this.cargaRutas(data);
      else
        this.editarRuta = false;
    });

    // this.reactiveForm.controls['idx_pao_diario_unidades'].valueChanges.subscribe(data => {
    //   if(typeof data !== 'undefined' && data != null)
    //     this.reactiveForm.get('desc_ruta')?.setValue(this.reactiveForm.get('dataServicio')?.value.desc_ruta
    //       +', '+this.reactiveForm.get('dataServicio')?.value.fecha)
    // });

  }

  ngOnInit(): void {
    this.cargaInicial();
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.anio = getYear;
    this.anio_filtro = getYear;
  }

  cargarListadoDeSemanasAnio_cast(num_anio:any) {
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    this.lstSemanas = [];
    this.semanaService.listarSemanas(anio_cast).subscribe((resp) => {
      this.lstSemanas = resp;
      //reiniciar
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error al cargar Semanas HM</b>. Contacte al Administrador.');
      });
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  limpiarfiltrosTabla() {
    this.dt.clear();
  }

  agregaMovimiento(){
    this.dgAddMovimiento = true;
    this.editarRuta = false;
    this.texto_operacion = 'Agregar Movimiento Dia Festivo';

    if(this.anio_filtro != 0)
    this.cargaListaDiaFestivo();

  }

  cargaListaDiaFestivo()
  {
    this.diafestivocolabservice.getListDiaFestivo(this.anio_filtro).subscribe(resp => {
      if(resp.length > 0){
        this.lstDiasFestivos = resp;
        setTimeout(() => {
          this.boxChofer = true;
        }, 600);
      }
      else
      {
        this.messageService.add({ severity: 'error', summary: 'Error en <Cargar Lista Dias Festivos>'
          , detail: "No fue posible cargar los Dias Festivos." });
        this.boxChofer = false;
      }
    },
    (error) => {
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Dias Festivos.');
    });
  }

  cargaListaChoferes(id_dia_festivo: number){
    this.lstChoferes = [];
    this.diafestivocolabservice.getListColabDispDiaFestivo(id_dia_festivo , this.anio_filtro).subscribe((resp) => {
      if(resp.length > 0){
        this.lstChoferes = resp;
        setTimeout(() => {
          this.boxRutas =true;
        }, 600);
      }else
      {
        this.messageService.add({ severity: 'error', summary: 'Error en <Cargar Lista Colaboradores Disponibles>'
          , detail: "No fue posible cargar los Colaboradores Disponibles." });
        this.boxRutas = false;
      }
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Colaboradores.');
      });
  }

  cargaRutas(cod_chofer: number){
    this.lstRutasDisp = [];
    this.diafestivocolabservice.getListRutasColabDiaFestivo(cod_chofer).subscribe((resp) => {
      if(resp.length > 0){
        this.lstRutasDisp = resp;
        setTimeout(() => {
          this.editarRuta=true;
        }, 600);
      }else
      {
        this.editarRuta=false;
        this.messageService.add({ severity: 'error', summary: 'Error en <Cargar Listado Rutas>'
          , detail: "No fue posible cargar Listado Rutas." });
      }
    },
    (error) => {
      this.editarRuta=false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado Rutas.');
    });
  }

  asignarValorFecha() {
    this.fecha_dia = this.fecha_filtrada;
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  //   guarda la informacion del colaborador
  guardarInformacionDeDiaFestivoColaborador() {
    this.fecha_dia = this.fecha_filtrada;
    let data_enviar: IPostColabDiaFestivo = {
      cod_chofer: this.reactiveForm.get('chofer')?.value,
      idx_pao_diario_unidades: this.reactiveForm.get('idx_pao_diario_unidades')?.value,
      id_diafestivo: this.reactiveForm.get('id_dia_festivo')?.value,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod
    };

    this.diafestivocolabservice.PostDiaFestivoColab(data_enviar).subscribe((resp) => {
      var resultado = resp;
      if (resultado.clv_estatus == 1) {
        setTimeout(() => {
          this.messageService.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.cancelarVentanaModal();
          this.cargaMovDiasFestivos();
        }, 800);
      } else if (resultado.clv_estatus == -2) {
        setTimeout(() => {
          this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Movimiento Dia Festivo>', detail: resultado.messageDetails });
          this.cancelarVentanaModal();
          this.cargaMovDiasFestivos();
        }, 800);
      }
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error en <Agregar Programación>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cancelarVentanaModal(){
    this.editarRuta = false;
    this.dgAddMovimiento = false;
    this.desc_ciudad = '';
    this.desc_ruta = '';
    this.lstChoferes = [];
    this.lstRutasDisp = [];
    this.reactiveForm.reset();
    this.reactiveForm.get('chofer')?.disable();
  }

  // cargar lista inicial para los dropdown  get
  cargaInicial(){
    this.dsblstCiudades=true;
    this.loading = true;
    this.ciudadService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp;
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_Estado: '', id_ciudad_principal: 0, clave_tipoTransporte: 0 });
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades=false;
      }, 1000);

    },
      (error) => {
        this.loading = false;

        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
      });
  }

  cargaMovDiasFestivos(){
    this.loading = true;
    let id_ciudad_hm = this.id_ciudad_hm == 0 || typeof this.id_ciudad_hm === 'undefined' ? 0 : this.id_ciudad_hm;

    this.diafestivocolabservice.getListMovDiaFestivoColab(id_ciudad_hm,this.anio_filtro,typeof this.user.cod === 'undefined' ? '0999': this.user.cod).subscribe(resp =>{
      if(resp.length>0)
      {
        this.lstMovDiaFestivoColab = resp;
        this.clv_operacion = this.lstMovDiaFestivoColab[0].clv_operacion;
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
      else{
        this.mostrarDialogoInformativoSinInformacion('<b>Atención</b> No se encontraro Movimientos de Dias Festivos');
        this.loading = false;
      }
    },
      (error) => {
        this.loading = false;

        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
    });
  }

  confirmarEliminar(data: IMovDiasFestivos) {
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar el <b>Movimiento</b> del Colaborador <b>'+data.nombre_completo+'</b>, para el dia <b>'+data.fecha_diafestivo+'</b>?',
      header: 'Confirmación de Eliminación',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.diafestivocolabservice.deleteDiaFestivoColab(data.idx,data.cod_chofer,typeof this.user.cod === 'undefined' ? '0999': this.user.cod).subscribe((resp) => {
          if (resp.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Eliminado', detail: 'Se eliminó correctamente el Movimiento' });
            this.cargaMovDiasFestivos();
          } else if (resp.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }

  mostrarDiaologoRutas(){
    this.dgRutas = true;
  }
  cancelarDialogoRutas() {
    this.dgRutas = false;
  }

  applyFilterGlobal2($event: any, stringVal: any) {
    this.dt2.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  onRowSelectRuta(data: IUltRutasDiaFestivo){
    this.desc_ruta = data.desc_ruta+', '+data.fecha;
    this.desc_ciudad = data.desc_ciudad;
    this.reactiveForm.get('idx_pao_diario_unidades')?.setValue(data.idx_pao_diario_unidades);
    this.dgRutas = false;
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_export_" + dateFormat + EXCEL_EXTENSION);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }


  // ----------------reportes  ------------
  abrirModalReportes() {
    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }

  cancelarModalReportes() {
    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
    this.selectedCiudad = {
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.fecha_fin = null;
  }

  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;

    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));

    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;

    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }

  // metodo para exportar a excel
  // exportarExcel(fecha_dia: string, fecha_fin: string) {
  //   let fecha_fin_cast = fecha_fin == null ? '1900-01-01' : fecha_fin;
  //   this.loading_reporte1 = true;
  //   if (fecha_dia == null) {
  //     this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
  //   } else {
  //     this.programacionDescansosService.getListadoDescasoFalta(fecha_dia, fecha_fin_cast, 0, this.user.cod == undefined ? '0999' : this.user.cod)
  //       .subscribe((resp: IDescansoFaltas[]) => {
  //         if (resp.length === 0 || typeof resp === 'undefined') {
  //           this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
  //           this.loading_reporte1 = false;
  //         } else {
  //           const rptDescansosFaltas: IrptDescansoFaltas[] = resp.map(descansoFalta => ({
  //             Colaborador: descansoFalta.nom_chofer,
  //             Puesto: descansoFalta.desc_puesto,
  //             Ciudad: descansoFalta.desc_ciudad_hm,
  //             Evento: descansoFalta.desc_evento,
  //             FechaRegistro: descansoFalta.fec_evento,
  //             UsuarioQueModifico: descansoFalta.nom_usuario_mod
  //           }));
  //           import("xlsx").then(xlsx => {
  //             const worksheet = xlsx.utils.json_to_sheet(rptDescansosFaltas);
  //             const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //             const excelBuffer: any = xlsx.write(workbook, {
  //               bookType: "xlsx",
  //               type: "array"
  //             });
  //             let texto: string = fecha_fin == '1900-01-01' || fecha_fin == null ? '' : '_Al_' + fecha_fin;
  //             this.saveAsExcelFile(excelBuffer, "GestiónDeEstatusDeRuta" + '_' + fecha_dia + texto);
  //           });
  //           setTimeout(() => {
  //             this.ButtonExportExcel = false;
  //             this.loading_reporte1 = false;
  //           }, 1000);
  //         }
  //       },
  //         (error) => {
  //           this.ButtonExportExcel = false;
  //           this.loading_reporte1 = false;
  //           this.fecha_fin = null;
  //           this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
  //         });
  //   }
  // }


  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }
  //metodo para ahcer las validaciones referentes a clv_operacion que es donde se autoriza o no al usuario a modificar
  validarClvOperacion(): boolean {

    if (this.clv_operacion == 1) {
      return this.fecha_hoy == this.fecha_filtrada ? true : false
    } else if (this.clv_operacion == 3) {
      return true;
    }
    return false;
  }

}
