<p-toast position="top-right" key="msj"></p-toast>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">

</p-confirmDialog>
<div class="container-fluid" style="border-color: #1e5a9c; border: 5px ; width: 95%;">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-shadow sinborde">
        <div class="card-header sinborde" style="background: none; ">
          <div class="cardicono rounded">
            <i class="pi pi-thumbs-up mt-3" style="font-size: 2rem; color: #fff;"></i>
          </div>
          <header style="left: 11%; top: 5%;">Apoyo
            <div style="color:#1e5a9c; display: inline;">a Colaboradores</div>
          </header>
          <div class="row d-flex justify-content-end">
            <div class="col-md-12">

              <!-- -------------fecha del filtro---------------- -->
              <div class="form-group row mt-5">


                <div class="row col-md-4 mt-2">
                  <div class="col-md-4">
                  </div>
                  <div class="col-md-8 mt-1">
                    <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                      [showClear]="false">
                      <ng-template let-target pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative; height: 25px;"
                          pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                          <span style="font-size: 14px; float: left; margin-top: 4px">{{target.nom_semana}}</span>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <div *ngIf="selectedSemana != null">
                      <div class="row">
                        <div style="color: #4fbe4f;" class="col-sm-6 text-left"><b>Inicio:
                            {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy'}}</b></div>
                        <div style="color: #4fbe4f;" class="col-sm-6 text-right"><b>Fin:
                            {{selectedSemana.fec_fin | date: 'dd/MM/yyyy'}}</b></div>
                      </div>
                    </div>
                  </div>
                </div>






                <!-- filtro de ciudades -->
                <div class="col-md-2 mr-3 mt-2">
                  <p-dropdown [(ngModel)]="id_ciudad_principal" [options]="lstCiudad" optionLabel="nombre"
                    optionValue="id" placeholder="Filtrar por Ciudad" [showClear]="false">
                  </p-dropdown>

                </div>

                <!-- ----------boton principal para el filtro  inicial------------------- -->
                <div class="col-md-1">
                  <button pButton pRipple type="button" (click)="FiltrarInformacion();" label="Filtrar"
                    icon="pi pi-filter" iconPos="left" class="custom-button"></button>
                </div>
                <!-- ----------boton principal de los reportes en excel------------------- -->

                <div class=" ml-4 col-md-3 d-flex justify-content-end">
                  <div>
                    <div class="col-md-6">
                      <div class="row d-flex justify-content-around">
                        <div class="col-md-9">
                          <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                            inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                            pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                        </div>
                        <div class="col-md-3">
                          <button pButton pRipple label="Cargar" class="custom-buttoncargar"icon="pi pi-filter"
                            (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                            pTooltip="Cargar Semanas del Año"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <button pButton pRipple type="button" (click)="abrirModalReportes();" label="Exportar"
                    icon="pi pi-file-excel" iconPos="left" class="custom-button-excel"></button>
                </div>

              </div>

            </div>
          </div>

        </div>
        <div class="card-body">
          <p-table #dt1 [value]="lstapoyo" [scrollable]="true" scrollHeight="flex" scrollDirection="both"
            [paginator]="true" [rows]="150" [rowsPerPageOptions]="[50,75,100,200]" [showCurrentPageReport]="true"
            currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
            [globalFilterFields]="['nom_chofer','desc_ciudad_hm','desc_puesto']" [loading]="loading">
            <ng-template pTemplate="caption">
              <div class="flex justify-content-between">
                <div>
                  <button pButton pRipple type="button" (click)="crearNuevo();" *ngIf="habilitaragregar"
                    label="Nuevo Apoyo" icon="pi pi-plus" [style]="{'width':'100%'}" iconPos="left"
                    class="custom-button">
                  </button>
                </div>
                <div class="p-input-icon-left p-ml-auto" *ngIf="habilitaragregar">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                    placeholder="Buscar" />
                </div>
                <div class="p-input-icon-left p-ml-auto" *ngIf="!habilitaragregar">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                    placeholder="Buscar" />
                </div>
              </div>

            </ng-template>
            <ng-template pTemplate="header">
              <tr>

                <th class="text-center">#Chofer</th>
                <th class="text-start" pSortableColumn="nom_chofer">Colaborador<p-sortIcon
                    field="nom_chofer"></p-sortIcon></th>
                <th class="text-center" pSortableColumn="desc_puesto">Puesto<p-sortIcon
                    field="desc_puesto"></p-sortIcon></th>
                <th class="text-center" pSortableColumn="id_ciudad_hm">Ciudad<p-sortIcon
                    field="id_ciudad_hm"></p-sortIcon></th>
                <th class="text-start">Categoria</th>
                <th class="text-center">#Semana</th>
                <th class="text-center" pSortableColumn="fec_inicio">Fecha de Inicio <p-sortIcon
                    field="fec_inicio"></p-sortIcon> </th>
                <th class="text-center" pSortableColumn="fec_fin">Fecha Fin<p-sortIcon field="fec_fin"></p-sortIcon>
                </th>
                <th class="text-center">Salario</th>
                <th class="text-center">#Dias</th>
                <th class="text-center" style="width: 200px; min-width: 200px;">Descripción</th>
                <th class="text-center">Importe Total</th>
                <th class="text-center" pSortableColumn="clv_estatus">Estatus<p-sortIcon
                    field="clv_estatus"></p-sortIcon></th>

                <th class="text-center">Opciones</th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
              <tr>
                <td class="text-center"><b>{{data.cod_chofer}}</b> </td>
                <td class="text-left"><b>{{data.nom_chofer}}</b></td>
                <td class="text-center" [ngStyle]="{'color': data.desc_puesto === 'DRIVER' ? '#1e5a9c' : '#70c0ec'}">
                  <!-- Azul Marino o Naranja -->
                  <b>{{data.desc_puesto}}</b>
                </td>
                <td class="text-center">{{data.desc_ciudad_hm}}</td>
                <td class="text-start" style="color: #1e5a9c;"><b>{{data.desc_apoyo}}</b></td>
                <td class="text-center">{{data.id_semana}}</td>

                <td class="text-center">{{data.fec_inicio | date: 'dd/MM/yyyy'}}</td>
                <td class="text-center">{{data.fec_fin | date: 'dd/MM/yyyy'}}</td>
                <td class="text-center" style="color: #1e5a9c"><b>{{data.imp_salario_base |currency: 'USD'}}</b></td>
                <td class="text-center" style="color: #FF6F61;"><b>{{data.num_duracion}}</b></td> <!-- Naranja -->
                <td class="text-justify" style="width: 200px; min-width: 200px; font-size: 13px;">
                  {{data.desc_motivo_apoyo | slice : 0:80 }}
                  <button type="button" class="btn p-button-text" (click)="verMas(data);" pTooltip="ver más"
                    tooltipPosition="top">
                    <i class="pi pi-comment color-icon mr-3" style="font-size: 1rem; color: #1e5a9c;"></i>
                  </button>
                </td>
                <td class="text-center" style=" color: #5de25d;">
                    <b>{{data.imp_total_apoyo | currency: 'USD'}}</b>
                </td>
                <td class="text-center">
                  <p-tag *ngIf="data.clv_estatus==1" class="custom-warning-tag" value="REQUIERE AUTORIZACION">
                  </p-tag>
                  <p-tag *ngIf="data.clv_estatus==2" class="custom-success-tag" value="VIGENTE">
                  </p-tag>
                </td>
                <td>
                  <div class="d-flex justify-content-around ">
                    <div class="d-flex">
                      <div class="ml-2">
                        <button class="custom-rounded-button custom-success-button" tooltipPosition="top"
                          pTooltip="Aprobar Apoyo" (click)="AprobarApoyo(data)">
                          <i class="pi pi-check"></i>
                        </button>
                      </div>

                      <div class="ml-2">
                        <button class="custom-rounded-button custom-danger-button" [disabled]="data.clv_activo === 2"
                          tooltipPosition="top" pTooltip="Eliminar Apoyo" (click)="EliminarApoyo(data)">
                          <i class="pi pi-trash"></i>
                        </button>
                      </div>

                      <div class="ml-2">
                        <button class="custom-rounded-button custom-primary-button" tooltipPosition="top"
                          pTooltip="Editar Apoyo" (click)="EditarApoyo(data)">
                          <i class="pi pi-pencil"></i>
                        </button>
                      </div>

                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <div class=" mt-3 d-flex justify-content-center">
            <p class="text-left informativo"
              style="display: inline; padding-left: 10px; border-left: 5px solid #5de25d; font-weight: normal; font-size: 0.8rem;">
              <b>VIGENTE</b> Ya ah sido aprobada y se contempla en nomina
            </p>
            <p class="text-left informativo"
              style="display: inline; padding-left: 10px; border-left: 5px solid #faad14; font-weight: normal; font-size: 0.8rem;">
              <b>REQUIERE AUTORIZACION</b> se encuentra espera de autorizacion
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--AGREGAR Apoyo-->
  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspAgregar" [style]="{width: '55vw','height':'700px'}"
    (onHide)="cancelarAgregar()" [transitionOptions]="'0ms'" styleClass="card-shadow">
    <ng-template pTemplate="header">
      Registro de Apoyos
    </ng-template>
    <ng-template pTemplate="content">
      <form class="form_cliente" [formGroup]="reactiveApoyos" (ngSubmit)="GuardarApoyo()">
        <div class="row  pb-2">
          <div class="row mt-1 d-flex justify-content-start">
            <div class="col-sm-6">
              <span class="title-light">Agrege la informacion solicitada
                <i class="pi pi-cog" style="size: 2rem;">
                </i>
              </span>
            </div>
          </div>
          <div class="form-group col-md-6 mt-2">

            <div class="row">


              <div class="col-sm-10  ml-4">
                <label style="color: #1e5a9c; " [ngClass]="{'texto_sm':true}"
                  class="label-header"><b>Colaborador</b></label>
                <p-dropdown [options]="lstChoferes" optionLabel="nombre_completo" formControlName="cod_chofer"
                  autoWidth="false" [showClear]="false" [filter]="true" [filterBy]="'nombre_completo'"
                  placeholder="Seleccione Colaborador" [style]="{'width':'95%' , 'margin-top': '5px'}"
                  optionValue="cod_chofer" (onChange)="changedp($event)">
                </p-dropdown>

              </div>
            </div>


          </div>
          <!-- fecha inicio y fin -->
          <div class="form-group col-md-6 mt2">

            <div class="row g-0">

              <div *ngIf="this.reactiveApoyos.controls['id_apoyo'].value === 2 ||this.reactiveApoyos.controls['id_apoyo'].value === 3||this.reactiveApoyos.controls['id_apoyo'].value === 4 ||this.reactiveApoyos.controls['id_apoyo'].value === 5"  class="col-sm-{{ id_editar === 1 ? '4' : '6' }} d-flex flex-column justify-content-around">
               <label style="color: #1e5a9c;"  class="label-header"><b>Fecha
                    Inicio</b></label>
                <input formControlName="fec_inicio" type="date" class="form-control sinborde"
                  pTooltip="Seleccione o teclee fecha Inicio" tooltipPosition="top" [style]="{'width':'80%' }">
              </div>

              <div  *ngIf="this.reactiveApoyos.controls['id_apoyo'].value === 1 ||this.reactiveApoyos.controls['id_apoyo'].value === 6||this.reactiveApoyos.controls['id_apoyo'].value === 7||this.reactiveApoyos.controls['id_apoyo'].value === 8" class="col-sm-6 d-flex flex-column justify-content-around">
               <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'90%'}"
                placeholder="Seleccione Semana*" formControlName="selectedSemana" optionLabel="nom_semana"
                [showClear]="false">
                <ng-template let-target pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                    pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                    <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="reactiveApoyos.controls['selectedSemana'].value">
                <div class="row">
                  <div style="color: #4fbe4f;" class="col-sm-6 text-left "><b>Inicio:
                      {{reactiveApoyos.controls['selectedSemana'].value.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                  <div style="color: #4fbe4f;" class="col-sm-6 text-right "><b>Fin:
                      {{reactiveApoyos.controls['selectedSemana'].value.fec_fin  | date: 'dd/MM/yyyy' }}</b></div>
                </div>
              </div>
              </div>


              <div class="col-sm-4 d-flex flex-column justify-content-around" *ngIf="id_editar === 1 && (this.reactiveApoyos.controls['id_apoyo'].value === 2 ||this.reactiveApoyos.controls['id_apoyo'].value === 3||this.reactiveApoyos.controls['id_apoyo'].value === 4 ||this.reactiveApoyos.controls['id_apoyo'].value === 5 )">
                <label style="color: #1e5a9c;" class="label-header"><b>Fecha
                    Fin</b></label>
                <input formControlName="fec_fin" type="date" class="form-control sinborde"
                [style]="{'width':'85%' }">
              </div>
              <div class="ml-3   col-sm-{{ id_editar === 1 ? '3' : '5' }} d-flex flex-column justify-content-around">
                <label style="color: #1e5a9c;" class="label-header"><b>Días de
                    Duración</b></label>
                <input formControlName="num_duracion" type="number" placeholder="0" class="form-control sinborde"
                  pTooltip="Días de duración del apoyo" tooltipPosition="top" max="28" min="0" [style]="{'width':'85%' }">
              </div>

            </div>
          </div>
        </div>

        <!-- tipo de incapacuidad -->
        <div class="row d-flex">


          <div class="col-md-6">
            <div class="row  ml-2">
              <label style="color: #1e5a9c;"
                class="label-header mt-2"><b>Ciudad</b></label>
              <div class="col-sm-10">
                <p-dropdown [options]="lstCiudad2" optionLabel="nombre" formControlName="id_ciudad_principal" autoWidth="false"
                  [showClear]="false" placeholder="Ciudad del colaborador acreedor"
                  [style]="{'width':'100%' , 'margin-top': '5px'}" optionValue="id">
                </p-dropdown>
              </div>


            </div>
            <div div class="row mt-2 ml-2  p-ml-auto">
              <label style="color: #1e5a9c; " class="label-header"><b>Tipo de
                  apoyo</b></label>
              <p-dropdown [options]="lstCatApoyos" optionLabel="desc_apoyo"
                formControlName="id_apoyo" autoWidth="false" [showClear]="false"
                placeholder="seleccione el tipo de apoyo" optionValue="id_apoyo" [style]="{'width':'80%' }" (onChange)="changeimp($event)">
              </p-dropdown>
            </div>





            <!-- plan celular-->
            <div class="row mt-2 ml-6 p-ml-auto" *ngIf="reactiveApoyos.controls['id_apoyo'].value === 1">
              <label style="color: #1e5a9c; " [ngClass]="{'texto_sm':true}" class="label-header">
                <b>Importe fijo</b></label>
              <div class="col-md-6 p-input-icon-left">
                <i class="pi pi-dollar ml-3"></i>
                <input pInputText type="text" optionLabel="imp_fijo"  formControlName="imp_fijo" optionValue="id_apoyo"
                  pTooltip="Ingrese el porcentaje capturado" />
              </div>

            </div>


             <!--fallecimientode un familiar-->
            <div class="row mt-2 ml-6 p-ml-auto" *ngIf="reactiveApoyos.controls['id_apoyo'].value === 2">
              <label style="color: #1e5a9c; " class="label-header"><b>Acotecimiento</b></label>
            <p-dropdown [options]="lstdias" optionLabel="desc_dias"
              formControlName="id_acotecimiento" autoWidth="false" [showClear]="false"
              placeholder="seleccione el tipo acotecimiento" optionValue="id" [style]="{'width':'80%' }" (onChange)="changedias($event)" >
            </p-dropdown>
            </div>


            <div class="row mt-2 ml-6 p-ml-auto" *ngIf="reactiveApoyos.controls['id_apoyo'].value === 6 ||reactiveApoyos.controls['id_apoyo'].value ===7||reactiveApoyos.controls['id_apoyo'].value ===8">
              <label style="color: #1e5a9c; " [ngClass]="{'texto_sm':true}" class="label-header">
                <b>Importe</b></label>
              <div class="col-md-6 p-input-icon-left">
                <i class="pi pi-dollar ml-3"></i>
                <input pInputText type="number" optionLabel="imp_fijo"  formControlName="imp_fijo" optionValue="id_apoyo" min="1"
                  pTooltip="Ingrese el porcentaje capturado" />
              </div>

            </div>



          </div>
          <div class="col-md-6 ">
            <label style="color:  #1e5a9c; font-weight: bold;">Motivo del apoyo</label>
            <div class=" d-flex">
              <textarea  rows="6" cols="70" pInputTextarea formControlName="desc_motivo_apoyo"></textarea></div>
          </div>
        </div>

        <div class="mt-8 d-flex justify-content-center">
          <button type="submit" class="custom-button-excel" [disabled]="
        !reactiveApoyos.controls['id_apoyo'].valid || !reactiveApoyos.controls['desc_motivo_apoyo'].valid
       " pTooltip="Registrar Apoyo">Guardar</button>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="cancelarAgregar();"></button>
    </ng-template>
  </p-dialog> <!--FIN AGREGAR PRESTAMO-->






<!--
  pdialog de reporte -->

  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div class="col-md-3">
              <p class="texto-pesado">Reporte de Apoyo</p>
            </div>
            <div class="col-md-3">
              <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                placeholder="Seleccione Semana*" [(ngModel)]="selectedSemanaReporte" optionLabel="nom_semana"
                [showClear]="false">
                <ng-template let-target pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                    pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                    <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="selectedSemanaReporte!=null">
                <div class="row">
                  <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                      {{selectedSemanaReporte.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                  <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                      {{selectedSemanaReporte.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <p-dropdown [(ngModel)]="id_ciudad_principalrpt" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
              </p-dropdown>
            </div>

            <div class="col-md-2 ml-6">
              <button type="button" [disabled]="deshabilitarbtnexportar" class="btn btn-outline-success"
                style="width: 100%;" (click)="exportarExcel()" [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>

    <!-- ver mas -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspVerMas" [style]="{width: '35vw','height':'430px'}"
    (onHide)="verMenos()" [transitionOptions]="'0ms'" styleClass="card-shadow">
    <ng-template pTemplate="header">
       Motivo del apoyo
    </ng-template>
    <ng-template pTemplate="content">
      <form class="form_cliente" [formGroup]="reactiveApoyos" (ngSubmit)="verMenos()">
        <div class="row  pb-2">

          <!-- tipo de incapacuidad -->

          <div class="row d-flex mt-3">
            <label style=" font-weight: bold;" class="title-light">Colaborador: {{this.nombre_chofer}}</label>
            <textarea rows="5" cols="50" pInputTextarea formControlName="desc_motivo_apoyo"></textarea>
          </div>
        </div>
        <div class="mt-2 d-flex justify-content-center">
          <button type="submit" class="custom-button-excel">ACEPTAR</button>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
        (click)="verMenos();"></button>
    </ng-template>
  </p-dialog>


</div>
