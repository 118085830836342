
import { Component } from '@angular/core';
import { IResultadoGeneral } from './../models/resultadogeneral';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { ICiudad } from '../models/ciudad';
import { IMes } from '../models/mes';
import { IEstatusForaneo } from '../models/estatusforaneo';
import { BonoaguinaldoService } from '../services/bonoaguinaldo.service';
import { ISemanasHappyMile } from '../models/semanas';
import { SemanaService } from '../services/semana.service';
import { AuthService } from '../services/auth.service';
import { ICiudadHM } from '../models/ciudadhm';
import { CiudadhmService } from './../services/ciudadhm.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BonoRecomendacionService } from '../services/bonorecomendacion.service';
import { INomina } from '../models/nomina';
import { IJsonDia } from '../models/json_dia';
import { ITipofiniquito } from '../models/tipofiniquito';
import { FiniquitoService } from '../services/finiquito.service';
import { IFiniquito } from '../models/finiquito';
import { IChoferBaja } from '../models/choferesbaja';
import { IPostFiniquito } from '../models/postfiniquito';
import { IEstatus } from '../models/estatus';

@Component({
  selector: 'app-finiquito',
  templateUrl: './finiquito.component.html',
  styleUrls: ['./finiquito.component.scss']
})
export class FiniquitoComponent {

  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudadHM[] = [];
  lstCiudad2: ICiudadHM[] = [];
  lstChoferes: IChoferBaja[] = [];
  lstchofCopy: IChoferBaja[] = [];
  lstEstatusForaneo: IEstatusForaneo[] = [];
  lstFiniquito: IFiniquito[] = [];
  lstSemanas: ISemanasHappyMile[] = [];
  lstNominaPorCiudad: INomina[] = [];
  lstJsonPorDia: IJsonDia[] = [];

  mssgListaCiudades: string = '';
  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };
  selectedSemanaReporte: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };


  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };

  //num_anio: number= 0;
  years: number[] = [];
  loading: boolean = false;
  anio_filtro: any;
  clv_activo: number = 0;
  //variables usadas para la fecha, fueron casteadas ya que presentaban errorees
  dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  anio: any = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  fecha_fin: any = null;
  id_ciudad_hm: number = 0;
  id_ciudad_principal: number = 0;
  texto_operacion: string = '';
  habilitar_agregar: boolean = false;
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  habilitaragregar: boolean = false;
  dspDeduccion: boolean = false;
  dsblstCiudades: boolean = true;
  dsblstColaboradores: boolean = true;
  dsblstCiudadesAdscritas: boolean = true;
  // variables de reportes--------------------------
  dbsReportes: boolean = false;
  fecha_fin_filtro: any = null;
  ButtonExportExcel: boolean = false;
  //Fecha de Dias Atras
  fecha_del_dia: any = null;
  mostrar_texto_fechas: number = 0;
  fecha_final_dia!: Date;
  //Fecha para restar 3 dias
  fecha_minus3 !: Date;
  fecha_dia_real: any = null;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }

  //---variables para el desgloce
  nombre_registro: string = '';
  nom_chofer: string = '';
  desc_puesto: string = '';
  dias_laborados: number = 0;
  imp_vacaciones: number = 0;
  imp_aguinaldo: number = 0;
  imp_prima: number = 0;
  imp_infonavit: number = 0;
  imp_descuentosvarios: number = 0;
  imp_prestamos: number = 0;
  imp_noordinarios: number = 0;
  imp_rutas_pendientes: number = 0;
  id_finiquito: number = 0;
  imp_finiquito: number = 0;
  fec_alta: Date = new Date;
  fec_baja: Date = new Date
  imp_tres_meses: number = 0;
  imp_prima_antiguedad: number = 0;
  imp_indemnizacion: number = 0;
  clv_estatus: number = 0;
  imp_salario: number = 0;
  dias_aguinaldo_proporcional: number = 0;
  num_dias_vacaciones: number = 0;

  dias_prima_proporcional: number = 0;
  imp_cajaahorro: number = 0;
  imp_Compensaciondemanda: number = 0;

  //-----
  imp_total_genral: number = 0;
  dspDesgloce: boolean = false;
  nombre_chofer: string = '';
  dspVerMas: boolean = false;


  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'Estatus pendiente'
  },
  {
    id: 2,
    nombre: 'Aceptado por el colaborador'

  },
  {
    id: 3,
    nombre: 'Rechazado por el colaborador'
  }

];





  anioReporte1: any;
  loading_reporte1: boolean = false;
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];


  lisTipoFiniquito: ITipofiniquito[] = [
    { id_finiquito: 2, desc_finiquito_list: 'Renuncia' },
    { id_finiquito: 1, desc_finiquito_list: 'Despido' },

  ];

  reactiveforminfonavit: FormGroup = new FormGroup({
    idx: new FormControl(0),
    nom_chofer: new FormControl(''),
    cod_chofer: new FormControl(0, [Validators.min(1), Validators.required]),
    id_ciudad_hm: new FormControl(0),
    num_anio: new FormControl(new Date().getFullYear()),
    //id_semana: new FormControl(0, [Validators.min(1),Validators.required]),
    selectedSemana: new FormControl([]),
    selectedSemanaagregar: new FormControl([],[Validators.min(1), Validators.required]),
    nombreCiudad: new FormControl(''),
    fec_inicio: new FormControl(''),
    fec_fin: new FormControl(''),
    fec_alta: new FormControl(''),
    fec_baja: new FormControl(''),
    clv_activo: new FormControl(0),
    id_finiquito: new FormControl(0, [Validators.min(1), Validators.required]),
    num_dias_vacaciones: new FormControl(0),
    desc_finiquito: new FormControl('', [Validators.maxLength(500), Validators.required]),
    clv_estatus_opciones: new FormControl(0),
    imp_cajaahorro: new FormControl(0),
    imp_Compensaciondemanda: new FormControl(0),
  });

  //Permisos
  user: ICredencialUsuario = {};
  constructor(

    private bonoaguinaldoService: BonoaguinaldoService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private semanaService: SemanaService,
    private finiquitoService: FiniquitoService,
    private auth: AuthService,
    private CiudadhmService: CiudadhmService,
    private bonoRecomendacionService: BonoRecomendacionService
  ) {

    this.initializeYears();
    // this.num_anio = new Date().getFullYear();
    this.cargarInformacionUsuario();

    this.reactiveforminfonavit.controls['selectedSemana'].valueChanges.subscribe(data => {
      if (data == null) {
        //this.resetearFormularios();
        this.reactiveforminfonavit.controls['fec_inicio'].setValue('');
        this.reactiveforminfonavit.controls['fec_fin'].setValue('');
      } else {
        this.reactiveforminfonavit.controls['fec_inicio'].setValue(data.fec_inicio);
        this.reactiveforminfonavit.controls['fec_fin'].setValue(data.fec_fin);
      }
    });
  }

  ngOnInit(): void {
    this.cargaInicial();
    this.cargarListadoDeSemanasAnio();
    this.cargaListaChoferes();
    this.inicializarFechas();

  }






  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }

  cargarListadoDeSemanasAnio_cast(num_anio: any) {
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    this.lstSemanas = [];
    this.semanaService.listarSemanas(anio_cast).subscribe((resp) => {
      this.lstSemanas = resp;
      //reiniciar
      this.lstNominaPorCiudad = [];
      this.lstJsonPorDia = [];
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error al cargar Semanas HM</b>. Contacte al Administrador.');
      });
  }


  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.anio = getYear;
    this.anio_filtro = getYear;
  }


  // metodos para eñ filtro para fechas
  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }

  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }



  cargarListadoDeSemanasAnio() {
    this.lstSemanas = [];
    this.semanaService.listarSemanas(this.anio).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }



  // -----------filtra y carga la informacion ----
  Filtrar() {

    this.loading = true;
    if (this.id_ciudad_hm == null || typeof this.id_ciudad_hm === 'undefined' || !this.selectedSemana || this.selectedSemana.id_semana == null || typeof this.selectedSemana.id_semana === 'undefined') {
      this.mostrarDialogoInformativoSincontacteadmi('<b>Informativo</b> Debe seleccionar una semana para poder filtrar');
      this.loading = false;
    } else {
      this.habilitaragregar = true;
      this.finiquitoService.getListadoFiniquito(this.selectedSemana.anio, this.id_ciudad_hm, this.selectedSemana.id_semana, this.user.cod == undefined ? '0999' : this.user.cod).subscribe(
        (resp) => {
          this.lstFiniquito = resp;
          this.lstChoferes = [];
          this.cargaListaChoferes();
          if (this.lstFiniquito.length === 0) {
            this.loading = false;
            this.mostrarDialogoInformativoSincontacteadmi('<b>No Existe</b> Registros para la Fecha seleccionada');
            this.imp_total_genral = 0;
          }
          else {
            this.imp_total_genral = 0;
            // Iterar sobre la lista y sumar los valores de los campos
            this.lstFiniquito.forEach((item) => {
              this.imp_total_genral += item.imp_finiquito;
            });
            setTimeout(() => {
              this.loading = false;
            }, 800);
          }
        },
        (error) => {
          this.mostrarDialogoInformativoSincontacteadmi('<b>ERROR</b> en Cargar Movimientos ');
          this.loading = false;
        }
      );
    }
  }


  verMenosdesgloce() {
    this.dspDesgloce = false
    this.imp_rutas_pendientes=0;
    this.nom_chofer ='';
    this.desc_puesto = '';
    this.dias_laborados  =0;
    this.imp_vacaciones = 0;
    this.imp_aguinaldo = 0;
    this.imp_prima = 0;
    this.imp_infonavit = 0;
    this.imp_infonavit = 0
    this.imp_descuentosvarios = 0;
    this.imp_prestamos = 0;
    this.imp_noordinarios = 0;
    this.imp_rutas_pendientes =0;
    this.imp_finiquito =0;
    this.fec_alta = new Date;
    this.fec_baja = new Date;
    this.imp_tres_meses = 0;
    this.imp_prima_antiguedad =0 ;
    this.imp_indemnizacion = 0;
    this.clv_estatus = 0;
    this.imp_salario =0;
    this.dias_aguinaldo_proporcional = 0;
    this.num_dias_vacaciones = 0;
    this.imp_cajaahorro = 0;
    this.imp_Compensaciondemanda =0;
  }


  mostrarDialogoInformativoSincontacteadmi(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }



  pdialogeditarDeduccion() {
    this.dspDeduccion = true;
    this.texto_operacion = '';
  }


  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  cargaListaChoferes() {
    this.dsblstColaboradores = true;
    this.lstChoferes = [];
    this.finiquitoService.getListadoChoferesBaja(this.id_ciudad_hm).subscribe((resp) => {
      this.lstChoferes = resp;
      setTimeout(() => {
        this.dsblstColaboradores = false;
      }, 100);
    },
      (error) => {
        this.dsblstColaboradores = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Colaboradores');
      });
  }



  cargaInicial() {
    this.dsblstCiudades = true;
    this.loading = true;
    this.CiudadhmService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp.slice(); // Crear una copia independiente
      this.lstCiudad2 = resp.slice(); // Crear una copia independiente
      const index = this.lstCiudad.findIndex(item => item.nombre === 'TODAS LAS CIUDADES');
      if (index !== -1) {
        this.lstCiudad.splice(index, 1);
      }
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_estado: '', id_ciudad_principal: 0, clave_tipoTransporte: 0 });
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 1000);
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
      });
  }


  changedp(event: any) {
    let eve = event.value;
    //this.lstCiudad2 = [];
    let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === eve);
    if (choferSeleccionado) {
      this.reactiveforminfonavit.controls['id_ciudad_hm'].setValue(choferSeleccionado.id_ciudad_hm);
      this.reactiveforminfonavit.controls['nombreCiudad'].setValue(choferSeleccionado.desc_ciudad_hm);
      this.reactiveforminfonavit.controls['nom_chofer'].setValue(choferSeleccionado.nombre_completo);
      this.reactiveforminfonavit.controls['fec_alta'].setValue(choferSeleccionado.fec_alta.toString().substring(0, 10));
      this.reactiveforminfonavit.controls['fec_baja'].setValue(choferSeleccionado.fec_baja.toString().substring(0, 10));
      this.reactiveforminfonavit.controls['num_dias_vacaciones'].setValue(choferSeleccionado.num_dias_vacaciones);
      this.reactiveforminfonavit.controls['imp_cajaahorro'].setValue(choferSeleccionado.imp_cajaahorro);
    } else {
      this.mssgListaCiudades = 'El colaborador no cuenta con ciudad favor de verificar';
    }
  }
  imp_noordinarios_viaticos: number = 0;
	imp_noordinarios_hospedaje: number= 0;
	imp_ordinarios_viaticos: number = 0;
	imp_ordinarios_hospedaje: number= 0;
  num_dias_laborados_anio: number= 0;

  mostrardesgloce(data: IFiniquito) {
    this.nom_chofer = data.nom_chofer;
    this.desc_puesto = data.desc_puesto;
    this.dias_laborados = data.dias_laborados;
    this.imp_vacaciones = data.imp_vacaciones;
    this.imp_aguinaldo = data.imp_aguinaldo;
    this.imp_prima = data.imp_prima;
    this.imp_infonavit = data.imp_infonavit;
    this.imp_infonavit = data.imp_infonavit;
    this.imp_descuentosvarios = data.imp_descuentosvarios;
    this.imp_prestamos = data.imp_prestamos;
    this.imp_noordinarios = data.imp_noordinarios;
    this.imp_rutas_pendientes = data.imp_rutas_pendientes;
    this.imp_finiquito = data.imp_finiquito;
    this.fec_alta = data.fec_alta;
    this.fec_baja = data.fec_baja;
    this.imp_tres_meses = data.imp_tres_meses;
    this.imp_prima_antiguedad = data.imp_prima_antiguedad;
    this.imp_indemnizacion = data.imp_indemnizacion;
    this.clv_estatus = data.clv_estatus;
    this.imp_salario = data.imp_salario;
    this.dias_aguinaldo_proporcional = data.dias_aguinaldo_proporcional;
    this.num_dias_vacaciones = data.num_dias_vacaciones;
    this.imp_cajaahorro = data.imp_cajaahorro;
    this.imp_Compensaciondemanda = data.imp_Compensaciondemanda;
    this.imp_noordinarios_viaticos= data.imp_noordinarios_viaticos ;
    this.imp_noordinarios_hospedaje = data.imp_noordinarios_hospedaje;
    this.imp_ordinarios_viaticos= data.imp_ordinarios_viaticos;
    this.imp_ordinarios_hospedaje = data.imp_ordinarios_hospedaje;
    this.num_dias_laborados_anio =data.num_dias_laborados_anio;


    this.nombre_registro = 'Desgloce'
    this.dspDesgloce = true
  }

  verMas(data: IFiniquito) {
    this.dspVerMas = true;
    this.nombre_chofer = data.nom_chofer;
    this.reactiveforminfonavit.controls['desc_finiquito'].setValue(data.desc_finiquito);
    this.reactiveforminfonavit.controls['desc_finiquito'].disable();
  }



  verMenos() {
    this.dspVerMas = false;
    this.reactiveforminfonavit.controls['desc_finiquito'].setValue('');
  }

  crearNuevo() {

    this.dspDeduccion = true;

    this.reactiveforminfonavit.controls['desc_finiquito'].enable();
    this.reactiveforminfonavit.controls['clv_activo'].setValue(1);
    this.texto_operacion = 'Agregar Nevo Finiquito';
    this.reactiveforminfonavit.controls['fec_alta'].disable();
    this.reactiveforminfonavit.controls['fec_baja'].disable();
    this.reactiveforminfonavit.controls['id_ciudad_hm'].disable();
    this.reactiveforminfonavit.controls['cod_chofer'].enable();
    this.reactiveforminfonavit.controls['num_anio'].disable();
    this.reactiveforminfonavit.controls['selectedSemana'].enable();
    this.reactiveforminfonavit.controls['imp_cajaahorro'].disable();
    //this.reactiveforminfonavit.controls['selectedSemanaagregar'].enable();

  }



  guardarFiniquito() {
    if (this.reactiveforminfonavit.controls['selectedSemanaagregar'].value.id_semana == 0){
      this.messageService.add({
        key: 'msj',
        severity: 'warn',
        summary: 'Cancelado',
        detail: 'Debes seleccionar una semana para guardar el finiquito'
      });
    }else{
      this.loading=true;
    let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === this.reactiveforminfonavit.controls['cod_chofer'].value);
    let nombreChofer = choferSeleccionado ? choferSeleccionado.nombre_completo : ''

    let data_enviar: IPostFiniquito = {
      idx: this.reactiveforminfonavit.controls['idx'].value,
      cod_chofer: this.reactiveforminfonavit.controls['cod_chofer'].value,
      id_ciudad_hm: this.reactiveforminfonavit.controls['id_ciudad_hm'].value,
      id_finiquito: this.reactiveforminfonavit.controls['id_finiquito'].value,
      num_anio: this.reactiveforminfonavit.controls['num_anio'].value,
      id_semana: this.reactiveforminfonavit.controls['selectedSemanaagregar'].value.id_semana,
      clv_activo: this.reactiveforminfonavit.controls['clv_activo'].value,
      fec_alta: this.reactiveforminfonavit.controls['fec_alta'].value,
      fec_baja: this.reactiveforminfonavit.controls['fec_baja'].value,
      num_dias_vacaciones: this.reactiveforminfonavit.controls['num_dias_vacaciones'].value,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      desc_finiquito: this.reactiveforminfonavit.controls['desc_finiquito'].value,
      imp_cajaahorro: this.reactiveforminfonavit.controls['imp_cajaahorro'].value,
      imp_Compensaciondemanda: this.reactiveforminfonavit.controls['imp_Compensaciondemanda'].value,

    };

    this.confirmationService.confirm({
      message: '¿Está seguro de guardar el <b>FINIQUITO</b> del Colaborador <b>' + nombreChofer + '</b>?',
      header: 'Confirmación antes de guardar finiquito',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.finiquitoService.postiFiniquito(data_enviar).subscribe((resp) => {
          //const resultado = Array.isArray(resp) ? resp[0] : resp;
          if (resp.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se registro correctamente' });
            this.dspDeduccion = false;
            this.loading=false;
            this.cancelarAgregar();
            this.Filtrar();

          }
          else if (resp.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
            this.loading=false;
          }
        });

      },
      reject: (type: any) => {
        switch (type) {

          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }

    });} this.loading=false;
  }

  cancelarAgregar() {

    this.texto_operacion = '';
    this.reactiveforminfonavit.controls['cod_chofer'].setValue(0);
    this.reactiveforminfonavit.controls['fec_alta'].setValue('');
    this.reactiveforminfonavit.controls['fec_baja'].setValue('');
    this.reactiveforminfonavit.controls['id_ciudad_hm'].setValue(0);
    this.reactiveforminfonavit.controls['id_finiquito'].setValue(0);
    this.reactiveforminfonavit.controls['num_anio'].setValue(new Date().getFullYear());
    this.reactiveforminfonavit.controls['desc_finiquito'].setValue('');
    this.reactiveforminfonavit.controls['clv_activo'].setValue('');
    this.reactiveforminfonavit.controls['num_dias_vacaciones'].setValue(0);
    this.reactiveforminfonavit.controls['imp_cajaahorro'].setValue(0);
    this.reactiveforminfonavit.controls['selectedSemanaagregar'].setValue('');


    this.dspDeduccion = false;
  }



  saveAsExcelFile(buffer: any, fileName: string): void {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_exportar_" + dateFormat + EXCEL_EXTENSION);
  }



  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }



  rechazarFiniquito(data: IFiniquito) {

    this.reactiveforminfonavit.controls['clv_activo'].setValue(5);

    let data_enviar: IPostFiniquito = {
      idx: data.idx,
      cod_chofer: data.cod_chofer,
      id_ciudad_hm: data.id_ciudad_hm,
      id_finiquito: data.id_finiquito,
      num_anio: data.num_anio,
      id_semana: data.id_semana,
      clv_activo: this.reactiveforminfonavit.controls['clv_activo'].value,
      fec_alta: data.fec_alta,
      fec_baja: data.fec_baja,
      num_dias_vacaciones: data.num_dias_vacaciones,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      desc_finiquito: data.desc_finiquito,
      imp_cajaahorro: data.imp_cajaahorro,
      imp_Compensaciondemanda: data.imp_Compensaciondemanda,
    };

    this.confirmationService.confirm({
      message: '¿Está seguro de <b>RECHAZAR</b> el <b>FINIQUITO</b> del Colaborador <b>' + data.nom_chofer + '</b>?',
      header: 'Confirmación antes <b>RECHAZAR</b> finiquito',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.finiquitoService.postiFiniquito(data_enviar).subscribe((resp) => {
          //const resultado = Array.isArray(resp) ? resp[0] : resp;
          if (resp.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se rechazo correctamente' });
            this.dspDeduccion = false;
            this.cancelarAgregar();

            this.Filtrar();

          }
          else if (resp.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
          }
        });

      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }


  aprobarFiniquito(data: IFiniquito) {


    this.reactiveforminfonavit.controls['clv_activo'].setValue(4);

    let data_enviar: IPostFiniquito = {
      idx: data.idx,
      cod_chofer: data.cod_chofer,
      id_ciudad_hm: data.id_ciudad_hm,
      id_finiquito: data.id_finiquito,
      num_anio: data.num_anio,
      id_semana: data.id_semana,
      clv_activo: this.reactiveforminfonavit.controls['clv_activo'].value,
      fec_alta: data.fec_alta,
      fec_baja: data.fec_baja,
      num_dias_vacaciones: data.num_dias_vacaciones,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      desc_finiquito: data.desc_finiquito,
      imp_cajaahorro: data.imp_cajaahorro,
      imp_Compensaciondemanda: data.imp_Compensaciondemanda,

    };

    this.confirmationService.confirm({
      message: '¿Está seguro de <b>APROBAR</b> el <b>FINIQUITO</b> del Colaborador <b>' + data.nom_chofer + '</b>?',
      header: 'Confirmación antes <b>APROBAR</b> finiquito',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.finiquitoService.postiFiniquito(data_enviar).subscribe((resp) => {
          //const resultado = Array.isArray(resp) ? resp[0] : resp;
          if (resp.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se agrego correctamente' });
            this.dspDeduccion = false;
            this.cancelarAgregar();
            this.Filtrar();

          }
          else if (resp.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
          }
        });

      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }




  editarDeduccion(data: IFiniquito) {

      const selectedWeek = this.lstSemanas.find(semana => semana.id_semana === data.id_semana);

      this.texto_operacion = 'Editar Deduccion ';
      this.dspDeduccion = true;


      let x = new Date(data.fec_alta);
      let y = x.toISOString()
      let z = new Date(data.fec_baja);
      let w = z.toISOString()

      this.reactiveforminfonavit.controls['id_ciudad_hm'].disable();
      this.reactiveforminfonavit.controls['cod_chofer'].disable();
      this.reactiveforminfonavit.controls['num_anio'].disable();
      //this.reactiveforminfonavit.controls['selectedSemanaagregar'].enable();
      this.reactiveforminfonavit.controls['fec_alta'].disable();
      this.reactiveforminfonavit.controls['fec_baja'].disable();
      this.reactiveforminfonavit.controls['imp_cajaahorro'].disable();


      this.reactiveforminfonavit.controls['idx'].setValue(data.idx);
      this.reactiveforminfonavit.controls['cod_chofer'].setValue(data.cod_chofer);
      this.reactiveforminfonavit.controls['id_ciudad_hm'].setValue(data.id_ciudad_hm);
      this.reactiveforminfonavit.controls['num_anio'].setValue(data.num_anio);
      this.reactiveforminfonavit.controls['id_finiquito'].setValue(data.id_finiquito);
      this.reactiveforminfonavit.controls['selectedSemanaagregar'].setValue(selectedWeek);
      this.reactiveforminfonavit.controls['clv_activo'].setValue(3);
      this.reactiveforminfonavit.controls['fec_alta'].setValue(y.substring(0, 10));
      this.reactiveforminfonavit.controls['fec_baja'].setValue(w.substring(0, 10));
      this.reactiveforminfonavit.controls['num_dias_vacaciones'].setValue(data.num_dias_vacaciones);
      this.user.cod == undefined ? '0999' : this.user.cod,
      this.reactiveforminfonavit.controls['desc_finiquito'].setValue(data.desc_finiquito);
      this.reactiveforminfonavit.controls['imp_cajaahorro'].setValue(data.imp_cajaahorro);
      this.reactiveforminfonavit.controls['imp_Compensaciondemanda'].setValue(data.imp_Compensaciondemanda);
  }




  EliminarRegistro(data: IFiniquito) {

    this.reactiveforminfonavit.controls['clv_activo'].setValue(2);

    let data_enviar: IPostFiniquito = {
      idx: data.idx,
      cod_chofer: data.cod_chofer,
      id_ciudad_hm: data.id_ciudad_hm,
      id_finiquito: data.id_finiquito,
      num_anio: data.num_anio,
      id_semana: data.id_semana,
      clv_activo: this.reactiveforminfonavit.controls['clv_activo'].value,
      fec_alta: data.fec_alta,
      fec_baja: data.fec_baja,
      num_dias_vacaciones: data.num_dias_vacaciones,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      desc_finiquito: data.desc_finiquito,
      imp_cajaahorro: data.imp_cajaahorro,
      imp_Compensaciondemanda: data.imp_Compensaciondemanda,

    };

    this.confirmationService.confirm({
      message: '¿Está seguro de eliminar el <b>FINIQUITO</b> del Colaborador <b>' + data.nom_chofer + '</b>?',
      header: 'Confirmación antes eliminar finiquito',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.finiquitoService.postiFiniquito(data_enviar).subscribe((resp) => {
          //const resultado = Array.isArray(resp) ? resp[0] : resp;
          if (resp.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se elimino correctamente' });
            this.dspDeduccion = false;
            this.cancelarAgregar();
            this.Filtrar();

          }
          else if (resp.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
          }
        });

      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }



  reciboPDF(clave_chofer: number, nom_chofer: string) {
    // let id_semana = this.selectedSemana ? this.selectedSemana.id_semana : 0;
    // let num_anio_semana = this.selectedSemana ? this.selectedSemana.anio : 0;

    // this.nominaService.getRecibo(this.num_anio_semana, id_semana, clave_chofer).subscribe(
    //   (archivo: Blob) => {
    //     const archivoUrl = URL.createObjectURL(archivo);

    //     // Reemplazar espacios por guiones bajos en el nombre del chofer
    //     const nombreArchivo = `ReciboDeNomina_semana_${this.selectedSemana.id_semana}_${this.selectedSemana.anio}_${nom_chofer.replace(/\s+/g, '_')}.pdf`;

    //     // Crear un enlace <a> para descargar el archivo
    //     const link = document.createElement('a');
    //     link.setAttribute('target', '_blank');
    //     link.setAttribute('href', archivoUrl);
    //     link.setAttribute('download', nombreArchivo);

    //     // Agregar el enlace al cuerpo del documento y activar el clic
    //     document.body.appendChild(link);
    //     link.click();

    //     // Limpiar el enlace del DOM después de la descarga
    //     document.body.removeChild(link);

    //     // Liberar recursos de la URL del archivo
    //     URL.revokeObjectURL(archivoUrl);
    //   },
    //   error => {
    //     console.error('Error al descargar el archivo:', error);
    //     // Manejar el error según tus necesidades
    //   }
    // );
  }
  // ----------------reportes  ------------

  abrirModalReportes() {
    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }


  cancelarModalReportes() {
    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
    this.selectedCiudad = {
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.fecha_fin = null;
    this.selectedSemanaReporte = {
      idx: 0,
      id_semana: 0,
      nom_semana: '',
      anio: 0,
      fec_inicio: '',
      fec_fin: '',
      clv_activo: 0
    };
    this.id_ciudad_hm_rpt = 0;
  }


  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));
    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;
    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }

  id_ciudad_hm_rpt: number = 0;
  deshabilitarbtnexportar: boolean = false;
  // metodo para exportar a excel
  exportarExcel() {
    this.deshabilitarbtnexportar = true;
    if (this.id_ciudad_hm_rpt == null || typeof this.id_ciudad_hm_rpt === 'undefined' || !this.selectedSemanaReporte || this.selectedSemanaReporte.id_semana == null || typeof this.selectedSemanaReporte.id_semana === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> Debe seleccionar una semana para poder filtrar');
      this.deshabilitarbtnexportar = false;
    } else {
      this.finiquitoService.rptFiniquito(this.selectedSemanaReporte.anio, this.selectedSemanaReporte.id_semana, this.id_ciudad_hm_rpt,)
        .subscribe((resp: IFiniquito[]) => {
          if (resp.length === 0 || typeof resp === 'undefined') {
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> debes de seleccionar parametros validos con registros existentes para poder exportar la informacion del Catalogo(Excel)');

            this.deshabilitarbtnexportar = false;
          } else {
            import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(resp);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
              const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
              });

              this.saveAsExcelFile(excelBuffer, "Reporte_Finiquito" + '_' + 'semana' + '_' + this.selectedSemanaReporte.id_semana + '_' + 'añio' + '_' + this.selectedSemanaReporte.anio);
            });
            setTimeout(() => {
              this.ButtonExportExcel = false;
              this.deshabilitarbtnexportar = false;
              //this.cancelarModalReportes()
            }, 1000);
          }
        },
          (error) => {
            this.ButtonExportExcel = false;
            this.deshabilitarbtnexportar = false;
            this.fecha_fin = null;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
          });
    }
  }



  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }


}
