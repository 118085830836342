<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 95%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-user-minus mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline">Consulta de</div>

              <div *ngIf="dia.length > 0" style="color:rgb(22, 48, 220);display:inline;" class="ml-3">Deducciones</div>
              <!-- - {{dia|date:'dd/MM/yyyy'}}
                 -->
            </header>

            <div class="row d-flex justify-content-between">
              <div class="col-md-12">
                <!-- -------------fecha del filtro---------------- -->
                <div class="form-group row mt-5">
                  <!-- <div class="col-md-1"></div> -->
                  <!-- filtro de semanas -->
                  <div class="col-md-3 ml-5">
                    <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                      [showClear]="false">
                      <ng-template let-target pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                          pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                          <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <div *ngIf="selectedSemana!=null">
                      <div class="row">
                        <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                            {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                        <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                            {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                      </div>
                    </div>
                  </div>

                  <!-- filtro de ciudades -->
                  <div class="col-md-2 mr-3 mt-2">
                    <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                      placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
                    </p-dropdown>
                  </div>

                  <!-- ----------boton principal para el filtro  inicial------------------- -->
                  <div class="col-md-1 ">
                    <button pButton pRipple type="button" (click)="Filtrar();" label="Filtrar" icon="pi pi-filter"
                      iconPos="left"></button>
                  </div>
                  <div class="col-md-1 "></div>
                  <div class=" ml-4 col-md-3 d-flex justify-content-end">
                    <div>
                      <div class="col-md-6">
                        <div class="row d-flex justify-content-around">
                          <div class="col-md-9">
                            <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                              inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                              pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                          </div>
                          <div class="col-md-3">
                            <button pButton pRipple label="Cargar" class="p-button-help" icon="pi pi-filter"
                              (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                              pTooltip="Cargar Semanas del Año"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ----------boton principal de los reportes en excel------------------- -->
                  <div class="  col-md-1 ml-4 ">
                    <button type="button"  pButton pRipple class="p-button-success " pTooltip="Exportar Catalogo a EXCEL"
                      tooltipPosition="right" (click)="abrirModalReportes()">
                      <i class="pi pi-file-excel"></i> Exportar
                    </button>
                  </div>
                </div>
              </div>
            </div>



            <div class="card-content">
              <div class="row mt-3">
                <!-- informacio  -->
                <p-table #dt1 [value]="lstDeduccion" [tableStyle]="{'min-width': '50rem'}"
                  styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                  scrollDirection="horizontal" responsiveLayout="scroll"
                  [globalFilterFields]="['nom_chofer','desc_puesto','desc_ciudad_hm', 'cod_chofer']"
                  [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true"
                  [resizableColumns]="false" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                  [loading]="loading">
                  <ng-template pTemplate="caption">
                    <div class="flex justify-content-end">

                      <div class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                          placeholder="Buscar: Codigo chofer, Nombre y Puesto"
                          pTooltip="Buscar por nombre del colaborador, ciudad de operacion o tipo transporte"
                        tooltipPosition="top" />
                      </div>
                    </div>

                  </ng-template>

                  <!-- tabulado -->
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" pSortableColumn="cod_chofer">#Chofer<p-sortIcon  field="cod_chofer"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="nom_chofer">Colaborador<p-sortIcon field="nom_chofer"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="id_ciudad_hm">Ciudad<p-sortIcon field="id_ciudad_hm"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_puesto">Puesto<p-sortIcon field="desc_puesto"></p-sortIcon></th>
                      <th class="text-center">Año</th>
                      <th class="text-center">Fecha Inicio</th>
                      <th class="text-center">Fecha Fin</th>
                      <th class="text-center">Deduccion Total</th>

                    </tr>

                  </ng-template>
                  <ng-template pTemplate="body" let-data let-index>
                    <tr>
                      <td class="text-center"><b>{{ data.cod_chofer }}</b></td>
                      <td class="justify-content-left"><b>{{ data.nom_chofer }}</b></td>
                      <td class="text-center">{{data.desc_ciudad_hm}}</td>
                      <td class="text-center"
                        [ngStyle]="{'color': data.desc_puesto ==='DRIVER'? 'rgba(60,  148, 224)' : 'rgba(60, 224, 122  )'}">
                        <b>{{ data.desc_puesto }}</b>
                      </td>
                      <td class="text-center">{{ data.num_anio }}</td>
                      <td class="text-center"><b>{{ data.fec_inicio | date: 'dd/MM/yyyy' }}</b></td>
                      <td class="text-center"><b>{{ data.fec_fin | date: 'dd/MM/yyyy' }}</b></td>
                      <td class="text-center" style="min-width:200px ;max-width: 200px; color: rgb(224, 111, 35);"
                        [ngClass]="{'ajustarcelda':true}">
                        <p class="my-0"><b>-{{data.imp_total | currency: 'USD'}}</b>
                          <span class="input-group-addon ml-2 my-0" (click)="mostrardesgloce(data)"
                            pTooltip="Desgloce de la deduccion">
                            <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1rem; color: #3B82F6;">
                            </i>
                          </span>
                        </p>
                      </td>



                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <tr>
                      <td colspan="2" class="text-right"></td>
                      <td colspan="5" class="text-right" style="color: rgb(169, 12, 12);">Total de ducciones</td>
                      <td style="color: rgb(13, 164, 13);">{{ imp_total_genral | currency: 'USD'}}</td>
                    </tr>
                  </ng-template>
                </p-table>

                <!-- pie de pagina -->
              </div>
              <div class=" mt-3 d-flex justify-content-center">
                <!-- <p class="text-left informativo" style="display: inline; padding-left: 10px; border-left: 5px solid rgb(49, 98, 189); font-weight: normal; font-size: 0.8rem;"><b>ACTIVO</b> el apoyo foráneo se encuentra en proceso</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(226, 189, 39); font-weight: normal; font-size: 0.8rem;"><b>INACTIVO</b> el apoyo foráneo se ha cancelado, y no se contempla para nomina</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(28, 163, 35); font-weight: normal; font-size: 0.8rem;">  <b>FINALIZADA</b> el apoyo foráneo se ha finalizado de forma correcta</p>
                <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;">  <b>SIN FINALIZAR</b> el apoyo foráneo no ah finalizado</p> -->
                <!-- <p class="text-left informativo"  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;"><b>La pagina no cuenta con opciones de edicion</b> </p> -->

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>




    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDesgloce"
      [style]="{width: '50vw', 'height': '570px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="verMenosdesgloce()">
      <ng-template pTemplate="header">
        {{ texto_operacion }}
        <div *ngIf="nombre_registro">
          {{ nombre_registro }}
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <form class="form_cliente" >
        <div class="mt-5 ">
          <span class="title-light mt-1">Información de Deducciones <i class="pi pi-cog" style="size: 2rem;"></i></span>
          <div class="deducciones-container">
            <div class="row d-flex mt-2">
              <label style="font-weight: bold;">
                <span style="color: rgb(10, 10, 10);">Colaborador:</span>
                <span style="color: rgb(30, 61, 233);">{{this.nom_chofer}}</span>
                <span style="color: orange;">({{this.desc_puesto}})</span>
              </label>
            </div>

            <div class="deduccion-item2 ">

              <span class="font-bold ml-2 texto_categoria">Tipo de deduccion:</span>
              <span class="font-bold ml-2 texto_categoria">#Semana: {{ id_semana }}</span>
              <span class="font-bold ml-2 texto_categoria">Fecha inicio {{this.fec_inicio |date: 'dd/MM/yyyy'}}</span>
              <span class="font-bold ml-2 texto_categoria">Fecha fin {{this.fec_fin |date: 'dd/MM/yyyy'}}</span>
              <span class="font-bold ml-2 texto_categoria mr-3">Importe&nbsp;&nbsp;</span>

            </div>
            <hr>
            <div  class="deduccion-item ">
              <span class="font-bold ml-2 texto_categoria">Echame la Mano</span>
              <span class="texto_verde text-center texto_resaltado">-{{ imp_deduccion_echamelaMano | currency }}&nbsp;&nbsp;</span>
            </div>
            <div class="deduccion-item ">
              <span class="font-bold ml-2 texto_categoria">Deducción Infonavit</span>
              <span class="texto_verde text-center texto_resaltado">-{{ imp_deduccion_infonavit | currency }}&nbsp;&nbsp;</span>
            </div>
            <div  class="deduccion-item ">
              <span class="font-bold ml-2 texto_categoria">Descuentos Varios</span>
              <span class="texto_verde text-center texto_resaltado">-{{ imp_deduccion_descuentos_varios | currency  }}&nbsp;&nbsp;</span>
            </div>
            <hr>
            <div class=" d-flex justify-content-end">
              <span><b>Total:&nbsp;&nbsp;</b></span>
              <span class="total-amount texto_verde texto_resaltado mr-3">-{{ imp_total | currency }}</span>
            </div>
          </div>
        </div>
      </form>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="verMenosdesgloce();"></button>
      </ng-template>
    </p-dialog>



    <!-- p-dialog de reportes excel ------------ -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div class="col-md-3">
              <p class="texto-pesado">Reporte de de ducciones</p>
            </div>
            <div class="col-md-3">
              <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                placeholder="Seleccione Semana*" [(ngModel)]="selectedSemanaReporte" optionLabel="nom_semana"
                [showClear]="false">
                <ng-template let-target pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                    pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                    <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="selectedSemanaReporte!=null">
                <div class="row">
                  <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                      {{selectedSemanaReporte.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                  <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                      {{selectedSemanaReporte.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <p-dropdown [(ngModel)]="id_ciudad_hm_rpt" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
              </p-dropdown>
            </div>
            <div class="col-md-2 ml-6">
              <button [disabled]="deshabilitarbtnexportar" type="button" class="btn btn-outline-success" style="width: 100%;" (click)="exportarExcel()"
                [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>
  </div>
